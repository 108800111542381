/* ------------- */
/* ADMIN - LOGIN */
/* ------------- */

$(function () {
    /**
     * Show/hide password
     */
    $('div#login > div > div > div:nth-of-type(2) > div.card-body > form > div.form-group > i.far.fa-eye-slash').on('mousedown', function () {
        $(this).prev().attr('type', 'text');
    }).on('mouseup', function () {
        $(this).prev().attr('type', 'password');
    }).on('mouseout', function () {
        $(this).prev().attr('type', 'password');
    });
});
